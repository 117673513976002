import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import Container from '../components/Container'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEO'
import StickyContainer from '../components/StickyContainer'
import { PageContext } from '../context/ApplicationState'

const TrainingLayout = ({ children, location, ...props }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState((pageState) => ({
      ...pageState,
      hasStickySiteNav: false,
      hasHeroSection: true,
    }))
  }, [])

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageContext(filter: { title: { eq: "Training" } }) {
        edges {
          node {
            ...HeroImageSettings
          }
        }
      }
    }
  `)

  return (
    <Layout {...props}>
      <SEO />

      <PageHeader
        imageMobile={
          data.allContentfulPageContext.edges[0].node.heroImageMobile
        }
        imageDesktop={
          data.allContentfulPageContext.edges[0].node.heroImageDesktop
        }
        title={props.pageName}
      />
      <StickyContainer
        stickyNavLinks={
          <>
            <Link
              to="/training/"
              activeClassName="active-page-link"
              className={
                location.pathname === '/training/training-materials/'
                  ? 'active-page-link'
                  : null
              }
            >
              Welcome
            </Link>
            <Link
              to="/training/culture/"
              activeClassName="active-page-link"
              partiallyActive
            >
              Culture
            </Link>
            <Link
              to="/training/tips/"
              activeClassName="active-page-link"
            >
              Tips for Success
            </Link>
            <Link
              to="/training/compliance/"
              activeClassName="active-page-link"
              partiallyActive
            >
              Compliance
            </Link>
          </>
        }
      >
        <Container className="wrap section">{children}</Container>
      </StickyContainer>
    </Layout>
  )
}

export default TrainingLayout
